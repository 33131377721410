<template>
  <div class="Home">
    <page-header/>
    <div class="uk-container">
      <section class="uk-section uk-padding-remove">
        <div class="uk-container-large uk-margin-remove" uk-grid>
          <div class="uk-width-1-1">
            <div class="uk-flex">
              <input type="text" class="uk-search uk-width-medium@s uk-padding-small"
                     placeholder="Organization name" style="outline: none" v-model="organizationinputval"/>
              <button type="button" class="uk-button uk-button-primary uk-margin-left" @click="AllOrganizationList(1)">Search</button>
              <button type="button" class="uk-button uk-button-default uk-margin-left" @click="window.location.reload()">Refresh</button>
            </div>
          </div>
          <div class=" uk-margin-large-bottom uk-padding-remove">
            <div class="uk-text-center uk-width-1-1 uk-margin-remove-left" uk-grid>
              <div v-for="(item, index) in organizs" :key="index"
                   v-show="item.organizId != 6 && item.organizId != 4">
                <div class="uk-padding-remove">
                  <div class="uk-card uk-card-default uk-card-hover uk-box-shadow-medium uk-padding-remove">
                    <div class="uk-card-media-top" v-show="item.organizLogo != ''">
                      <img  :src="item.organizLogo" style="height: 160px; width: 200px;"
                            class="uk-padding uk-padding-remove-bottom uk-padding-top" v-if="item.organizLogo.includes('https') || item.organizLogo.includes('http')">
                      <img  v-else :src="'/uploadingDir/organizicons/'+item.organizLogo" style="height: 160px; width: 200px;"
                            class="uk-padding uk-padding-remove-bottom uk-padding-top">
                    </div>
                    <div class="uk-card-media-top" v-show="item.organizLogo == ''">
                      <img  src="../../assets/country.png" style="height: 160px; width: 200px;"
                            class="uk-padding uk-padding-remove-bottom uk-padding-top">
                    </div>
                    <div class="uk-card-body uk-padding-small">
                      <h5 class="uk-text-bold uk-margin-remove-top uk-margin-small-bottom uk-width-expand
                                  uk-text-center" style="color: black; min-height: 50px;">
                        {{item.organizName.substring(0,25)}}</h5>
                      <p class="uk-margin-remove-top uk-text-center uk-margin-remove-bottom">
                        {{item.description.substring(0,8)}}</p>
                    </div>
<!--                    <div class="uk-subnav uk-flex uk-flex-center" uk-grid>-->
<!--                      <button type="button" v-on:click="SRequest(item.organizId)" class="uk-button uk-button-danger uk-button-small uk-margin-left-->
<!--                                uk-margin-bottom">{{$t("studentOrganizations.reg")}}-->
<!--                      </button>-->
<!--                    </div>-->
<!--                    <div class="uk-card-footer">-->
<!--                      <button type="button" v-on:click="SRequest(item.organizId)" class="uk-button uk-button-danger uk-button-small uk-margin-left-->
<!--                                uk-margin-bottom">{{$t("studentOrganizations.reg")}}-->
<!--                      </button>-->
<!--                    </div>-->
                    <div class="uk-card-footer uk-padding-remove">
                      <button class="uk-button uk-button-primary uk-button-large uk-width-1-1"
                              @click="StudentRequest(item)"
                              style="border-bottom-left-radius: 15px; border-bottom-right-radius: 15px;
                                font-weight: 700; background: #0d9df9; color: #fff; font-size: 12px; line-height: 2.5;
                                padding: 16px 10px;"> {{ $t("registrequest") }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <li class="school " v-for="(item, index) in organizs" :key="index">
                  <div class="uk-card uk-card-default uk-card-hover uk-box-shadow-medium" id="card">
                    <div class="uk-card-media-top">
                        <img :src="item.organizLogo" alt=""  class="uk-padding uk-padding-remove-bottom uk-padding-top">
                    </div>
                    <div class="uk-card-body uk-padding-small">
                        <h5 class="uk-text-bold uk-margin-remove-top uk-margin-small-bottom uk-width-expand
                          uk-text-center" style="color: black;">{{item.examName}}</h5>
                        <p class="uk-margin-remove-top uk-text-center uk-margin-remove-bottom">{{item.description}}</p>
                    </div>
                      <div class="uk-subnav uk-flex uk-flex-center" uk-grid>
                        <button type="button" class="uk-button uk-button-danger uk-button-small uk-margin-left uk-margin-bottom">register</button>
                    </div>
                  </div>
                </li>
                <li class="partners " v-for="(item, index) in organizs" :key="index">
                    <div class="uk-card uk-card-default uk-card-hover uk-box-shadow-medium" id="card">
                      <div class="uk-card-media-top">
                          <img :src="item.organizLogo" alt=""  class="uk-padding uk-padding-remove-bottom uk-padding-top">
                      </div>
                      <div class="uk-card-body uk-padding-small">
                          <h5 class="uk-text-bold uk-margin-remove-top uk-margin-small-bottom uk-width-expand
                            uk-text-center" style="color: black;">{{item.examName}}</h5>
                          <p class="uk-margin-remove-top uk-text-center uk-margin-remove-bottom">{{item.description}}</p>
                      </div>
                        <div class="uk-subnav uk-flex uk-flex-center" uk-grid>
                          <button type="button" class="uk-button uk-button-danger uk-button-small uk-margin-left uk-margin-bottom">register</button>
                      </div>
                  </div>
                </li> -->
            </div>
          </div>
        </div>
      </section>
      <section class="uk-section uk-padding-remove ">
        <div class="uk-container">
          <div class="uk-card-footer uk-text-center">
            <div class="uk-flex uk-flex-center">
              <v-pagination
                  v-model="currentPage"
                  :page-count="pages"
                  :classes="uikitClasses"
                  :labels="Labels"
                  @change="onChange"
              ></v-pagination>
            </div>
          </div>
        </div>
      </section>
    </div>
    <page-footer/>
  </div>

</template>


<script>
  import StudentService from '@/services/StudentService'
  const PageHeader = () => import("./StudentHeaderNew.vue");
  const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")
  import vPagination from "@/components/globals/vue-plain-pagination"
  import {mapGetters} from "vuex";
  // import UIkit from 'uikit'
  export default {
    name: 'Home',
    components: {
      vPagination,
      PageHeader,
      PageFooter
    },
    created() {
      this.AllOrganizationList(this.currentPage)
      this.organizationId = this.$route.params.organizationId;
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser'])
    },
    data() {
      return {
        error: null,
        exams: null,
        latestexams: null,
        myOrganizCount: null,
        organizs: [],
        myorganizs: [],
        myExamCount: null,
        userexams: null,
        items: null,
        requestCode: '',
        organizationinputval: '',
        organizationId: '',
        examDate: "",
        currentPage: 1,
        pages: 0,
        examDateEnd: "",
        uikitClasses: {
          ul: "uk-pagination",
          li: "",
          liActive: "uk-active",
          liDisable: "uk-disabled",
          button: "page-link"
        },
        Labels: {
          first: "<span uk-icon='chevron-double-left'; ratio = '1.2'></span>",
          prev: "<span uk-pagination-previous></span>",
          next: "<span uk-pagination-next></span>",
          last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
        },
        lang: {
          days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          placeholder: {
            dateRange: "Start date ~ End date"
          }
        },
        shortcuts: [
          {
            text: "Today",
            onClick: () => {
              this.time3 = [new Date(), new Date()];
            }
          }
        ],
        timePickerOptions: {
          start: "00:00",
          step: "00:30",
          end: "23:30"
        }
      }
    },
    methods: {
      async StudentRequest(item) {
        try {
          const response = await StudentService.StudentRequest({
            organizId: item.organizId,
            studentCode: this.currentUser.email,
          });
          if (response.data.status == 200) {
            if (response.data.success == true) {
              alert(response.data.message);
            } else {
              alert(response.data.message);
            }
          } else {
            alert(response.data.message);
          }
        } catch (error) {
          console.log(error)
        }
      },
      onChange: function () {
        this.AllOrganizationList(this.currentPage);
      },
      // organization хүсэлт явуулах
      async SRequest(oooo) {
        try {
          const response = await StudentService.StudentRequest({
            studentCode: this.requestCode,
            organizId: oooo
          })
          if (response.data.status == 200) {
            if (response.data.success) {
              alert("Organization Reguest Success" + response.data.message);
            } else {
              alert("Organization Reguest Warning " + response.data.message);
            }
          }
          console.log("Organization response data :")
          console.log(response.data)


        } catch (error) {

          // if (response.data.status == 400) {
          //   if (response.data.success) {
          //     alert(response.data.message);
          //   }else{
          // alert(response.data.message);
          //   }
          // }
          // console.log("Status:400 Response message :")
          // console.log(response.data.message)

          console.log(error)
        }
      },
      async AllOrganizationList(page) {
        try {
          if (page > 0) {
            page = page - 1;
          }
          console.log("Search by examname" + this.organizationinputval)
          const response = await StudentService.AllOrganizationList({
            page: page,
            organizName: this.organizationinputval,
            type: "other",
          })
          console.log(response)
          if (response.status == 200) {
           this.organizs = response.data.content
            this.pages = response.data.totalPages;
          }
        } catch (error) {
          console.log(error)
        }
      },
    },
  }
</script>

<style scoped>
  .date-picker {
    width: 165px;
    height: 0px;
  }

  #divider {
    border-style: solid;
    border-width: 0.4px;
    color: rgb(219, 219, 224);
    border-top-color: rgb(175, 173, 175) !important;
    width: 100%;
  }

  .uk-search {
    border: 1px solid;
    border-color: #01B0FF;
    border-radius: 8px;
  }

  .uk-card {
    border-radius: 15px;
    border: 1px solid white;
    width: 200px;
    height: 330px;
  }

  .uk-card:hover {
    border-radius: 15px;
    border: 1px solid blue;
  }
  .uk-card-footer{
    border-top: 1px solid #e1e1e1;
  }
</style>
